import {
  McModal,
  McButton,
  McIcon,
  McCheckbox,
} from "@maersk-global/mds-react-wrapper";
import react, { useState } from "react";
import { postAPI } from "../../../../services/CepApiServices";
import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "../InitiateOfferAccept.scss";
interface IPROPS {
  isModalOpen?: boolean;
  onLoadData?: any;
  onCloseFunc?: any;
  submitData?: any;
  indicationId?: any;
  renderFunc?: any;
  negotiationType?: any;
  SOData?: any;
  checkWriteAccess?: any;
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
const OfferTimeModal = ({
  isModalOpen,
  onLoadData,
  onCloseFunc,
  renderFunc,
  submitData,
  indicationId,
  negotiationType,
  SOData,
  checkWriteAccess,
}: IPROPS) => {
  const [valueTime, onChangeDateTime] = useState<any>(new Date());
  const [SOTimeLineSelectedForBCP, setSOTimeLineSelectedForBCP] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const timeValue = moment(valueTime).format("YYYY-MM-DDTHH:mm");
    const payload = {
      standardTemplateMainTerms: onLoadData,
      negotiationTimelineDetail: {
        termsAcceptTimeline: timeValue,
        extensionRequestTimeline: null,
        isLocked: true,
      },
      isStandardTermsSent: true,
    };

    const payloadBCP = {
      indicationId: Number(indicationId),
      timeLine: SOTimeLineSelectedForBCP
        ? SOData?.negotiationTimelineDetail?.termsAcceptTimeline
        : timeValue,
      // timeLine: timeValue,
      negotiationType: negotiationType[0],
    };

    const payloadLiftSubs = {
      indicationId: Number(indicationId),
      timeLine: timeValue,
    };
    console.log(payloadBCP);
    let response;
    if (negotiationType[0] === "Standard Offer Document") {
      response = await postAPI(
        `InitialOffer/SendStandardTerms?indicationId=${indicationId}&negotiationType=Standard Offer Document`,
        payload
      );
    } else if (negotiationType[0] === "Contract Document") {
      response = await postAPI(
        `InitialOffer/InitiateNegotiationProcess`,
        payloadBCP
      );
    } else {
      response = await postAPI(`Negotiation/InitiateLiftSub`, payloadLiftSubs);
    }
    if (
      negotiationType[0] === "Standard Offer Document" &&
      response?.status !== 400
    ) {
      toast.success(`Standard terms/MainOffer submitted`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    } else if (
      negotiationType[0] === "Standard Offer Document" &&
      response?.status !== 200
    ) {
      toast.error(`Standard terms/MainOffer submission failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    } else if (
      negotiationType[0] === "Contract Document" &&
      response?.status !== 400
    ) {
      toast.success(`Charter party negotiation initiated`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    } else if (
      negotiationType[0] === "Contract Document" &&
      response?.status !== 200
    ) {
      toast.error(`Charter party negotiation failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    } else if (
      negotiationType[0] === "Lift Subs" &&
      response?.StatusCode !== 400
    ) {
      toast.success(`LiftSubs initiated successfully`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    } else if (
      negotiationType[0] === "Lift Subs" &&
      response?.StatusCode !== 200
    ) {
      toast.error(`LiftSubs initiation failed`, {
        theme: "colored",
        icon: false,
        autoClose: 3000,
      });
      renderFunc();
      onCloseFunc();
      setIsSubmitting(false);
    }
  };
  const onCancelData = () => {
    onCloseFunc();
  };
  const selectSOTimeLineForBCP = (e) => {
    setSOTimeLineSelectedForBCP(e.target.__checked);
  };
  return (
    <McModal
      height="500px"
      width="500px"
      className="offer-submission-modal"
      heading={negotiationType?.[1]}
      open={isModalOpen}
      backdropcloseactiondisabled
    >
      <div className="date-value">
        <div className="checkbox-section">
          {SOData?.negotiationTimelineDetail?.termsAcceptTimeline &&
            negotiationType[0] === "Contract Document" && (
              <McCheckbox
                onchange={selectSOTimeLineForBCP}
                label="Use same line as Standard Offer"
                value={SOData?.negotiationTimelineDetail?.termsAcceptTimeline}
                data-testid="checkbox"
              ></McCheckbox>
            )}
        </div>
        {!SOTimeLineSelectedForBCP && (
          <div className="date-section">
            {negotiationType[0] === "Lift Subs" ? (
              <label>Please Select LiftSubs Timeline</label>
            ) : (
              <label>Please Select Acceptance Date</label>
            )}
            <DateTimePicker
              format="MM/dd/yyyy HH:mm"
              onChange={onChangeDateTime}
              value={valueTime}
            />
          </div>
        )}
      </div>
      {checkWriteAccess && (
        <>
          <McButton
            slot="primaryAction"
            variant="primary"
            fit="small"
            label={negotiationType[2]}
            onClick={handleSubmit}
            data-testid="submit-button"
            disabled={isSubmitting}
          ></McButton>
          <McButton
            slot="secondaryAction"
            variant="secondary"
            dialogAction="cancel"
            onClick={onCancelData}
            role="cancel-button"
            fit="small"
          >
            Cancel
          </McButton>
        </>
      )}
    </McModal>
  );
};
export default OfferTimeModal;
