import {
    McModal,
    McButton,
    McTextarea,
  } from "@maersk-global/mds-react-wrapper";
  import { useState } from "react";
  
  import { toast } from "react-toastify";
  import { postAPI } from "../../../services/CepApiServices";
  interface IPROPS {
    indicateRequestId?: any;
    isModalOpen?: boolean;
    onLoadData?: any;
    onCloseFunc?: any;
    submitData?: any;
    reloadApi?: any;
  }
  const AddNewTermsModal = ({
    indicateRequestId,
    isModalOpen,
    onLoadData,
    onCloseFunc,
    reloadApi,
  }: IPROPS) => {
    const [newTermTitle, setNewTermTitle] = useState<string>("");
    const [newTermValue, setNewTermValue] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [contentError, setContentError] = useState(false);
  
    const handleSubmit = async () => {
      if (newTermTitle.trim() === "") {
        setTitleError(true);
        return;
      }
      if (newTermValue.trim() === "") {
        setContentError(true);
        return;
      }
      setIsSubmitting(true);
      const newObjData = {
        indicationId: Number(indicateRequestId),
        title: newTermTitle,
        description: newTermValue,
      };
      const callSaveAPI = await postAPI(
        `InitialOffer/SaveAdditionalMainTerms`,
        newObjData
      );
      if (callSaveAPI.StatusCode) {
        if (callSaveAPI.Message.includes("Title already exists")) {
          toast.error(`Title already exists`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
        } else {
          toast.error(`Failed adding New main terms`, {
            theme: "colored",
            icon: false,
            autoClose: 3000,
          });
        }
      } else {
        toast.success(`New main terms added`, {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
        reloadApi();
      }
      onCloseFunc();
      setIsSubmitting(false);
      setNewTermTitle("");
      setNewTermValue("");
    };
  
    const onCancelData = () => {
      onCloseFunc();
      setNewTermTitle("");
      setNewTermValue("");
    };
    return (
      <McModal
        height="430px"
        width="510px"
        heading={"Add New Term"}
        open={isModalOpen}
        backdropcloseactiondisabled
      >
        <div className="header-modal">
          <McTextarea
            data-testid="rider-clause-title"
            label="Title"
            input={(e: any) => {
              setTitleError(false);
              setNewTermTitle(e.target.value);
            }}
            value={newTermTitle}
            rows={2}
            invalid={titleError}
            errormessage="Please enter title"
          ></McTextarea>
        </div>
  
        <div>
          <McTextarea
            data-testid="rider-clause-content"
            label="Term Content"
            input={(e: any) => {
              setContentError(false);
              setNewTermValue(e.target.value);
            }}
            value={newTermValue}
            rows={6}
            invalid={contentError}
            errormessage="Please enter term content"
          ></McTextarea>
        </div>
  
        <McButton
          slot="primaryAction"
          variant="primary"
          fit="small"
          label={"Submit"}
          onClick={handleSubmit}
          disabled={isSubmitting}
          data-testid="submit-button"
        ></McButton>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          dialogAction="cancel"
          onClick={onCancelData}
          role="cancel-button"
          fit="small"
        >
          Cancel
        </McButton>
      </McModal>
    );
  };
  export default AddNewTermsModal;
  